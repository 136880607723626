import React from "react";
import {
	HeaderImage,
	Paragraph,
	Mason2,
	Cards,
	ContactCall,
	Slider,
	BorderPattern,
} from "../components/rows";

function Home() {
	return (
		<>
			{
				<HeaderImage
					src_background="sized-hero-4262119066_799d13a55c_o.jpg"
					text="Endangered Cultures & Societies&hellip;"
					img_height="70vh"
					class_text="col-auto text-light fw-medium  px-5 mx-auto pb-2 h1"
					text_background_color="rgba(160, 82, 19,.6)"
				/>
			}
			{<BorderPattern img_src="border-pattern.jpg" alt_src="" />}
			<div className="bg-dark ">
				{
					<Paragraph
						//////container
						class_Container="container  pt-5 "
						class_Container_Title="row text-center mt-0 mx-auto "
						//////Title

						class_Title="h4 pt-4 text-light fw-light "
						content_Title="Visit the Endangered Cultures of the World Before they Fade Away
					   " //////container
						//////Paragraph container

						class_Container_Paragraph="row text-center mt-4 w-85 px-3 mx-auto "
						//////Paragraph 1

						class_Paragraph_1="h6 fw-light lh-lg text-light"
						content_Paragraph_1="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Nullam malesuada erat ut turpis. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis."
						//////Paragraph 2

						class_Paragraph_2="h6 fw-light lh-lg text-light hidden"
						content_Paragraph_2=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis in!"
						//////Paragraph 3

						class_Paragraph_3="h6 fw-light lh-lg text-light hidden"
						content_Paragraph_3=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis in!"
					/>
				}
				{
					<Slider classContainer='container mb-4 '
						class_row="row w-50 mx-auto p-4"
						class_col="col"
						class_title_1="h4 pt-0 text-light"
						class_title_2="h4 pt-0 text-light"
						class_title_3="h4 pt-0 text-light"
						class_title_4="h4 pt-0 text-light"
						class_title_5="h4 pt-0 text-light"
						class_title_6="h4 pt-0 text-light"
						class_title_7="h4 pt-0 text-light"
						class_title_8="h4 pt-0 text-light"
						class_paragraph_1="p fw-light lh-lg text-light"
						class_paragraph_2="p fw-light lh-lg text-light"
						class_paragraph_3="p fw-light lh-lg text-light"
						class_paragraph_4="p fw-light lh-lg text-light"
						class_paragraph_5="p fw-light lh-lg text-light"
						class_paragraph_6="p fw-light lh-lg text-light"
						class_paragraph_7="p fw-light lh-lg text-light"
						class_paragraph_8="p fw-light lh-lg text-light"
						alt_1="img"
						alt_2="img"
						alt_3="img"
						alt_4="img"
						alt_5="img"
						alt_6="img"
						alt_7="img"
						alt_8="img"
						title_1="lorem ipsome"
						title_2="lorem ipsome"
						title_3="lorem ipsome"
						title_4="lorem ipsome"
						title_5="lorem ipsome"
						title_6="lorem ipsome"
						title_7="lorem ipsome"
						title_8="lorem ipsome"
						paragraph_1="lorem ipsome some more"
						paragraph_2="lorem ipsome some more"
						paragraph_3="lorem ipsome some more"
						paragraph_4="lorem ipsome some more"
						paragraph_5="lorem ipsome some more"
						paragraph_6="lorem ipsome some more"
						paragraph_7="lorem ipsome some more"
						paragraph_8="lorem ipsome some more"
						image_1="images/endangered-P1160543_fs.jpg"
						image_2="images/endangered_P1020648_fs.jpg"
						image_3="images/endangered-DSC_1616_fs.jpg"
						image_4="images/endangered-jose-aragones-860577.jpg"
						image_5="images/endangered-klub-boks-10500827.jpg"
						image_6="images/endangered-P1020442_fs.jpg"
						image_7="images/endangered-P1160530_fs.jpg"
						image_8="images/endangered-P1050810_fs.jpg" ></Slider>
				}
				{
					<Paragraph
						//////container
						class_Container="container  pt-5 "
						class_Container_Title="row text-center mt-0 mx-auto "
						//////Title

						class_Title="h4 fw-light mt-4 mb-5 text-light "
						content_Title="Facculpa nis nonsequi ius aut occab idem eum quidi ip de pa qui beaqua?" 
						//////container
						//////Paragraph container

						class_Container_Paragraph="row text-center mt-4 w-85 px-3 mx-auto hidden"
						//////Paragraph 1

						class_Paragraph_1="p fw-light text-light"
						content_Paragraph_1=" See a slideshow tour of the highlights of my trip from Rabat, Chefchouan, Volulblis, Fes, the desert, Skoura, Asni, Mar
						See a slideshow tour of the highlights of my trip from Rabat, Chefchouan, Volulblis, Fes, the desert, Skoura, Asni, Marrakech, rakech, 
						"
						//////Paragraph 2

						class_Paragraph_2="p fw-light hidden"
						content_Paragraph_2=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis in!"
						//////Paragraph 3

						class_Paragraph_3="p fw-light hidden"
						content_Paragraph_3=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis in!"
					/>
				}
				{<Mason2


					class_Container="container"
					class_row="row w-75 mx-auto"
					class_col_1="col-3"
					class_col_2="col-9 "
					class_row_inner="row  mason2"
					class_img="img-fluid"
					class_header_1="h5 pt-0 text-light fw-med"
					class_header_2="h5 pt-0 text-light fw-med"
					class_header_3="h5 pt-0 text-light fw-med"
					class_header_4="h5 pt-0 text-light fw-med"
					class_header_5="h5 pt-0 text-light fw-med"
					class_header_6="h5 pt-0 text-light fw-med"
					class_text_1="p fw-light text-light pb-4"
					class_text_2="p fw-light text-light pb-4"
					class_text_3="p fw-light text-light pb-4"
					class_text_4="p fw-light text-light pb-4"
					class_text_5="p fw-light text-light pb-4"
					class_text_6="p fw-light text-light pb-4"
					image_1="sized-186x186-IMG_3266.jpg"
					image_2="sized-186x186-maasai.jpg"
					image_3="sized-186x186-julia-volk-5205708.jpg"
					image_4="sized-186x186-india07_oxen.jpg"
					image_5="endangered-DSC_1724_fs.jpg"
					alt_1=""
					alt_2=""
					alt_3=""
					alt_4=""
					alt_5=""
					label_1='Lorem Ipsom'
					label_2='Lorem Ipsom'
					label_3='Lorem Ipsom'
					label_4='Lorem Ipsom'
					label_5='Lorem Ipsom'

					header_1="Endangered Cultures: Nation or Tribe "
					header_2="Endangered Cultures: Nation or Tribe "
					header_3="Endangered Cultures: Nation or Tribe "
					header_4="Endangered Cultures: Nation or Tribe "
					header_5="Endangered Cultures: Nation or Tribe "
					header_6="Endangered Cultures: Nation or Tribe "
					text_1="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede."
					text_2="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede."
					text_3="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede."
					text_4="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede."
					text_5="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede."
					text_6="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede."

				/>
				}
				<div className="spacer"></div>
				<div className="spacer"></div>
				{
					<ContactCall
						class_Container="container mt-0 mb-0  pb-0 "
						class_row="row justify-content-center"
						class_link="decorate-none"
						link_target="tel:+1-503-296-7913"
						class_Paragraph_1="col-auto text-light  border border-1 text-end h6 p-3"
						class_Paragraph_2="col-auto text-light  border border-1 bg-green text-start h6 p-3"
						content_Paragraph_1="To Arrange Your Adventure of a Lifetime, Call:"
						content_Paragraph_2="+1 (503) 296-7913"
					/>
				}

			</div>
			<div className="spacer"></div>
			<div className="spacer"></div>

			<div>
			{<BorderPattern img_src="border-pattern.jpg" alt_src="" />}
			<div className="bg-light ">

				<div className="spacer"></div>
				<div className="spacer"></div>

				{
					<Cards
						class_Container="container bg-beige p-0  mt-0 pb-5"
						class_row="row g-1 g-xl-1  m-0  p-0 mx-auto pb-5"
						class_col_1="col my-3 "
						class_col_2="col my-3 "
						class_col_3="col my-3 "
						class_col_4="col my-3 "
						class_col_5="col my-3 "
						class_img="rounded img-fluid m-auto img-zoom"
						class_card="card card-size mb-4  bg-transparent mb-lg-0 "
						class_card_body="card-body card-offset bg-white  rounded shadow"
						class_drop_menu="card-drop-menu"
						class_drop_item="card-drop-menu-item"
						image_1="thumb-img-7.jpg"
						alt_1="image"
						image_2="card-ship.jpg"
						alt_2="image"
						image_3="thumb-img-9.jpg"
						alt_3="image"
						image_4="thumb-img-10.jpg"
						alt_4="image"
						image_5="thumb-img-11b.jpg"
						alt_5="image"
						class_header_1=" h4 card-header1 fw-bold ps-1"
						class_header_2=" h6 card-header2 fw-bold ps-1"
						class_ul_list="h6 p-1 ps-4 list-group"
						class_li_list="mb-1  border-0 card-link-bullet"
						class_text_1="p fw-light lh-base card-link "
						class_text_2="p fw-light lh-base card-link text-light"
						header_1=""
						header_2=""
						header_3=""
						header_4=""
						header_5=""
						header_6=""
						subtitle_1=""
						subtitle_2=""
						subtitle_3=""
						subtitle_4=""
						subtitle_5=""
						subtitle_6=""
						col_1_text_1="africa"
						col_1_href_1="./africa"
						col_1_text_2="africa"
						col_1_href_2="./africa"
						col_1_text_3="africa"
						col_1_href_3="./africa"
						col_1_text_4="africa"
						col_1_href_4="./africa"
						col_1_text_5="africa"
						col_1_href_5="./africa"
						col_1_text_6="africa"
						col_1_href_6="./africa"
						col_2_text_1="africa"
						col_2_href_1="./africa"
						col_2_text_2="africa"
						col_2_href_2="./africa"
						col_2_text_3="africa"
						col_2_href_3="./africa"
						col_2_text_4="africa"
						col_2_href_4="./africa"
						col_2_text_5="africa"
						col_2_href_5="./africa"
						col_2_text_6="africa"
						col_2_href_6="./africa"
						col_3_text_1="africa"
						col_3_href_1="./africa"
						col_3_text_2="africa"
						col_3_href_2="./africa"
						col_3_text_3="africa"
						col_3_href_3="./africa"
						col_3_text_4="africa"
						col_3_href_4="./africa"
						col_3_text_5="africa"
						col_3_href_5="./africa"
						col_3_text_6="africa"
						col_3_href_6="./africa"
						col_4_text_1="africa"
						col_4_href_1="./africa"
						col_4_text_2="africa"
						col_4_href_2="./africa"
						col_4_text_3="africa"
						col_4_href_3="./africa"
						col_4_text_4="africa"
						col_4_href_4="./africa"
						col_4_text_5="africa"
						col_4_href_5="./africa"
						col_4_text_6="africa"
						col_4_href_6="./africa"
						col_5_text_1="africa"
						col_5_href_1="./africa"
						col_5_text_2="africa"
						col_5_href_2="./africa"
						col_5_text_3="africa"
						col_5_href_3="./africa"
						col_5_text_4="africa"
						col_5_href_4="./africa"
						col_5_text_5="africa"
						col_5_href_5="./africa"
						col_5_text_6="africa"
						col_5_href_6="./africa"
					/>

				}
				<div className="spacer"></div>

			</div>
			</div>
			{<BorderPattern img_src="border-pattern.jpg" alt_src="" />}
		</>
	);
}

export default Home;
