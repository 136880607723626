import React from "react";
import {
	HeaderImage,
	Paragraph,
	ParagraphCols,
	ImgBlockRows,
	Cards,
	ContactCall,
	BorderPattern,
} from "../components/rows";

function Home() {
	return (
		<>
			{
				<HeaderImage
					src_background="ekrulila-3837487Short.jpg"
					text="Multi-generational & Family Travel&hellip;"
					img_height="50vh"
					class_text="col-auto text-light fw-med  px-5 mx-auto pb-2 h1"
					text_background_color="rgba(160, 82, 19,.6)"
				/>
			}
			{<BorderPattern img_src="border-pattern.jpg" alt_src="" />}
			<div className="spacer"></div>
			<div className="bg-dark ">
				{
					<Paragraph
						//////container
						class_Container="container pt-5 "
						class_Container_Title="row text-center mt-0 mx-auto"
						//////Title

						class_Title="h3 fw-light pt-0 text-light"
						content_Title="Ways To Go: Family Vacations to Remember" //////container
						//////Paragraph container

						class_Container_Paragraph="row text-center mt-4 w-85 px-3 mx-auto "
						//////Paragraph 1

						class_Paragraph_1="h6 fw-light lh-lg text-light"
						content_Paragraph_1=" Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Lorem ipsum dolor sit amet, consectetuer adipiscing elit."
						//////Paragraph 2

						class_Paragraph_2="p fw-light lh-lg hidden"
						content_Paragraph_2=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis in!"
						//////Paragraph 3

						class_Paragraph_3="p fw-light lh-lg hidden"
						content_Paragraph_3=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis in!"
					/>
				}
				{
					<ParagraphCols
						class_Container="container text-light mb-0"
						class_row="row my-4 w-75  mx-auto"
						class_col_1="col"
						class_col_2="col"
						class_col_3="col"
						class_header_1="mt-4 mb-3 h5 fw-med"
						class_header_2="mt-4 mb-3 h5 fw-med"
						class_header_3="mt-4 mb-3 h5 font-med"
						class_text_1="p fw-light mt-4 mb-3 text-light"
						class_text_2="p fw-light mt-4 mb-3 text-light"
						class_text_3="p fw-light mt-4 mb-3 text-light"
						header_1="Family Trip Idea One"
						header_2="Family Trip Idea One"
						header_3="Family Trip Idea One"
						text_1="Accuptas ium que vent venditis
						doluptatum quatemperem con con
						ecus. Elit quidelit, optio dolenduc
						iet ilicatur, que nos et facimus eius,
						quae. Uci nis velendi genitatum
						renimus magniment fuga. A"
						text_2="Accuptas ium que vent venditis
						doluptatum quatemperem con con
						ecus. Elit quidelit, optio dolenduc
						iet ilicatur, que nos et facimus eius,
						quae. Uci nis velendi genitatum
						renimus magniment fuga. "
						text_3="Accuptas ium que vent venditis
						doluptatum quatemperem con con
						ecus. Elit quidelit, optio dolenduc
						iet ilicatur, que nos et facimus eius,
						quae. Uci nis velendi genitatum
						renimus magniment fuga. Amusam
						quosam earciet ut dolor."
					/>
				}
				<div className="spacer"></div>
				{
					<ImgBlockRows
						class_Container="container p-0  mb-0 "
						class_row="row  mx-5 ps-5 pe-5 g-0 w-85 mx-auto "
						class_col_1="col"
						class_col_2="col "
						class_col_3="col "
						class_col_4="col hidden"
						class_col_5="col hidden"
						class_col_6="col hidden"
						class_labels="mt-4 "
						class_img="img-fluid w-100 "
						class_label="text-center fst-italic text-light"
						image_1="FPO-sized-296x275stock-photo-mother-with-kids-and-luggage-looking-at-planes-in-airport-1076529395.jpg"
						alt_1="image"
						label_1="Lorem Ipsom"
						image_2="sized-296x275-cj-lily.jpg"
						alt_2="image"
						label_2="Lorem Ipsom"
						image_3="sized-296x275-rodnae-productions-5637748.jpg"
						alt_3="image"
						label_3="Lorem Ipsom"
						image_4=""
						alt_4="image"
						label_4="Lorem Ipsom"
						image_5="sized_180-DSC01669_fs.jpg"
						alt_5="image"
						label_5="Lorem Ipsom"
						image_6="sized_180-DSC01669_fs.jpg"
						alt_6="image"
						label_6="Lorem Ipsom"
					/>
				}
				<div className="spacer"></div>
				<div className="spacer"></div>

				{
					<ImgBlockRows
						class_Container="container p-0  mb-0"
						class_row="row g-0  w-50 mx-auto"
						class_col_1="col"
						class_col_2="col hidden"
						class_col_3="col hidden"
						class_col_4="col hidden"
						class_col_5="col hidden"
						class_col_6="col hidden"
						class_labels="mt-4 "
						class_img="img-fluid w-100 "
						class_label="text-center fst-italic text-light"
						image_1="FPO-750x500-istockphoto-1086889670-1024x1024.jpg"
						alt_1="image"
						label_1="Lorem Ipsom"
						image_2="sized_180-DSC01669_fs.jpg"
						alt_2="image"
						label_2="Lorem Ipsom"
						image_3="sized_180-DSC01669_fs.jpg"
						alt_3="image"
						label_3="Lorem Ipsom"
						image_4="sized_180-DSC01669_fs.jpg"
						alt_4="image"
						label_4="Lorem Ipsom"
						image_5="sized_180-DSC01669_fs.jpg"
						alt_5="image"
						label_5="Lorem Ipsom"
						image_6="sized_180-DSC01669_fs.jpg"
						alt_6="image"
						label_6="Lorem Ipsom"
					/>
				}
				<div className="spacer"></div>

				{
					<Paragraph
						//////container
						class_Container="container pt-0  mb-0"
						class_Container_Title="row text-center mt-0 mx-auto "
						//////Title

						class_Title="h5 pt-0 text-light fw-med"
						content_Title="Donec Nec Justo Eget Felis Facilisis Termentum" //////container
						//////Paragraph container

						class_Container_Paragraph="row text-center mt-4 w-85 px-3 mx-auto "
						//////Paragraph 1

						class_Paragraph_1="p fw-light text-light"
						content_Paragraph_1=" Praesent dapibus, neque id cursus faucibus, tortor neque egestas auguae, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus."
						//////Paragraph 2

						class_Paragraph_2="p fw-light lh-lg hidden"
						content_Paragraph_2=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis in!"
						//////Paragraph 3

						class_Paragraph_3="p fw-light lh-lg hidden"
						content_Paragraph_3=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis in!"
					/>
				}
				<div className="spacer"></div>

				{
					<ContactCall
						class_Container="container mt-0 mb-0  pb-0 "
						class_row="row justify-content-center"
						class_link="decorate-none"
						link_target="tel:+1-503-296-7913"
						class_Paragraph_1="col-auto text-light  border border-1 text-end h6 p-3"
						class_Paragraph_2="col-auto text-light  border border-1 bg-green text-start h6 p-3"
						content_Paragraph_1="To Arrange Your Adventure of a Lifetime, Call:"
						content_Paragraph_2="+1 (503) 296-7913"
					/>
				}
				<div className="spacer"></div>
				<div className="spacer"></div>

				{<BorderPattern img_src="border-pattern.jpg" alt_src="" />}

			</div>
			<div className="bg-light ">
			<div className="spacer"></div>
			<div className="spacer"></div>

				{
					<Cards
						class_Container="container bg-beige p-0  mt-0 pb-5"
						class_row="row g-1 g-xl-1  m-0  p-0 mx-auto pb-5"
						class_col_1="col my-3 "
						class_col_2="col my-3 "
						class_col_3="col my-3 "
						class_col_4="col my-3 "
						class_col_5="col my-3 "
						class_img="rounded img-fluid m-auto img-zoom"
						class_card="card card-size mb-4  bg-transparent mb-lg-0 "
						class_card_body="card-body card-offset bg-white  rounded shadow"
						class_drop_menu="card-drop-menu"
						class_drop_item="card-drop-menu-item"
						image_1="thumb-img-7.jpg"
						alt_1="image"
						image_2="card-ship.jpg"
						alt_2="image"
						image_3="thumb-img-9.jpg"
						alt_3="image"
						image_4="thumb-img-10.jpg"
						alt_4="image"
						image_5="thumb-img-11b.jpg"
						alt_5="image"
						class_header_1=" h4 card-header1 fw-bold ps-1"
						class_header_2=" h6 card-header2 fw-bold ps-1"
						class_ul_list="h6 p-1 ps-4 list-group"
						class_li_list="mb-1  border-0 card-link-bullet"
						class_text_1="p fw-light lh-base card-link "
						class_text_2="p fw-light lh-base card-link text-light"
						header_1=""
						header_2=""
						header_3=""
						header_4=""
						header_5=""
						header_6=""
						subtitle_1=""
						subtitle_2=""
						subtitle_3=""
						subtitle_4=""
						subtitle_5=""
						subtitle_6=""
						col_1_text_1="africa"
						col_1_href_1="./africa"
						col_1_text_2="africa"
						col_1_href_2="./africa"
						col_1_text_3="africa"
						col_1_href_3="./africa"
						col_1_text_4="africa"
						col_1_href_4="./africa"
						col_1_text_5="africa"
						col_1_href_5="./africa"
						col_1_text_6="africa"
						col_1_href_6="./africa"
						col_2_text_1="africa"
						col_2_href_1="./africa"
						col_2_text_2="africa"
						col_2_href_2="./africa"
						col_2_text_3="africa"
						col_2_href_3="./africa"
						col_2_text_4="africa"
						col_2_href_4="./africa"
						col_2_text_5="africa"
						col_2_href_5="./africa"
						col_2_text_6="africa"
						col_2_href_6="./africa"
						col_3_text_1="africa"
						col_3_href_1="./africa"
						col_3_text_2="africa"
						col_3_href_2="./africa"
						col_3_text_3="africa"
						col_3_href_3="./africa"
						col_3_text_4="africa"
						col_3_href_4="./africa"
						col_3_text_5="africa"
						col_3_href_5="./africa"
						col_3_text_6="africa"
						col_3_href_6="./africa"
						col_4_text_1="africa"
						col_4_href_1="./africa"
						col_4_text_2="africa"
						col_4_href_2="./africa"
						col_4_text_3="africa"
						col_4_href_3="./africa"
						col_4_text_4="africa"
						col_4_href_4="./africa"
						col_4_text_5="africa"
						col_4_href_5="./africa"
						col_4_text_6="africa"
						col_4_href_6="./africa"
						col_5_text_1="africa"
						col_5_href_1="./africa"
						col_5_text_2="africa"
						col_5_href_2="./africa"
						col_5_text_3="africa"
						col_5_href_3="./africa"
						col_5_text_4="africa"
						col_5_href_4="./africa"
						col_5_text_5="africa"
						col_5_href_5="./africa"
						col_5_text_6="africa"
						col_5_href_6="./africa"
					/>

				}
				{<BorderPattern img_src="border-pattern.jpg" alt_src="" />}

			</div>

		</>
	);
}

export default Home;
